export default function Store(attrs) {
  this.brand_id = attrs.brand_id ? attrs.brand_id : null
  this.name = attrs.name ? attrs.name : ''
  this.address = attrs.address ? attrs.address : null
  this.address_description = attrs.address_description ? attrs.address_description : null
  this.coordinates = attrs.coordinates ? attrs.coordinates : null
  this.logo_url = attrs.logo_url ? attrs.logo_url : ''
  this.background_url = attrs.background_url ? attrs.background_url : ''
  this.payment_details = attrs.payment_details ? attrs.payment_details : null
  this.recipient_account = attrs.recipient_account ? attrs.recipient_account : null
  this.payment_options = attrs.payment_options ? attrs.payment_options : ["2000", "5000", "20000", "50000"]
  this.tips_options = attrs.tips_options ? attrs.tips_options : ["5%", "10%", "15%", "20%"]
  this.default_product_tax = attrs.default_product_tax ? attrs.default_product_tax : ''
  this.wallet_type = attrs.wallet_type ? attrs.wallet_type : 'personal'
  this.pos_type_id = attrs.pos_type_id ? attrs.pos_type_id : null
  this.spot_id = attrs.spot_id ? attrs.spot_id : null
  this.default_lang = attrs.default_lang ? attrs.default_lang : 'ru'
  this.pay_for_bill = attrs.pay_for_bill ? attrs.pay_for_bill : false
  this.tips_commission = attrs.tips_commission ? attrs.tips_commission : 5
  this.ask_mode = attrs.ask_mode ? attrs.ask_mode : 0
  this.check_location = attrs.check_location ? attrs.check_location : 0
  this.call_waitress = attrs.call_waitress ? attrs.call_waitress : 0
  this.call_hookah = attrs.call_hookah ? attrs.call_hookah : 0
  this.call_bill = attrs.call_bill ? attrs.call_bill : 0
  this.can_reserve = attrs.can_reserve ? attrs.can_reserve : 0
  this.show_rating = attrs.show_rating ? attrs.show_rating : true
  this.show_menu = attrs.show_menu ? attrs.show_menu : false
  this.show_marketplace = attrs.show_marketplace ? attrs.show_marketplace : false
  this.pay_guest = attrs.pay_guest ? attrs.pay_guest : true
  this.cashback = attrs.cashback ? attrs.cashback : null
  this.has_cashdesk = attrs.has_cashdesk ? attrs.has_cashdesk : false
  this.can_receive_order = attrs.can_receive_order ? attrs.can_receive_order : false
  this.tin = attrs.tin ? attrs.tin : ''
  this.mfo = attrs.mfo ? attrs.mfo : ''
  this.tax = attrs.tax ? attrs.tax : ''
  this.account_no = attrs.account_no ? attrs.account_no : ''
  this.hide_logo = attrs.hide_logo ? attrs.hide_logo : false
  this.button_color = attrs.button_color ? attrs.button_color : ''
  this.login_for_tips = attrs.login_for_tips ? attrs.login_for_tips : ''
  this.login_for_bill = attrs.login_for_bill ? attrs.login_for_bill : ''
  this.login_for_reserve = attrs.login_for_reserve ? attrs.login_for_reserve : ''
  this.login_for_order = attrs.login_for_order ? attrs.login_for_order : ''
  this.login_for_delivery = attrs.login_for_delivery ? attrs.login_for_delivery : ''
  this.tips_notification = attrs.tips_notification ? attrs.tips_notification : 0
  this.classifications = attrs.classifications ? attrs.classifications : []
  this.cuisines = attrs.cuisines ? attrs.cuisines : []
  this.additionals = attrs.additionals ? attrs.additionals : []
  this.stickers = attrs.stickers ? attrs.stickers : []
  this.mo_open = attrs.mo_open ? attrs.mo_open : null
  this.tu_open = attrs.tu_open ? attrs.tu_open : null
  this.we_open = attrs.we_open ? attrs.we_open : null
  this.th_open = attrs.th_open ? attrs.th_open : null
  this.fr_open = attrs.fr_open ? attrs.fr_open : null
  this.sa_open = attrs.sa_open ? attrs.sa_open : null
  this.su_open = attrs.su_open ? attrs.su_open : null
  this.mo_close = attrs.mo_close ? attrs.mo_close : null
  this.tu_close = attrs.tu_close ? attrs.tu_close : null
  this.we_close = attrs.we_close ? attrs.we_close : null
  this.th_close = attrs.th_close ? attrs.th_close : null
  this.fr_close = attrs.fr_close ? attrs.fr_close : null
  this.sa_close = attrs.sa_close ? attrs.sa_close : null
  this.su_close = attrs.su_close ? attrs.su_close : null
  this.day_n_night = attrs.day_n_night ? attrs.day_n_night : 0
  this.mark = attrs.mark ? attrs.mark : 0
  this.tips_enabled = attrs.tips_enabled ? attrs.tips_enabled : true
  this.order_confirm = attrs.order_confirm ? attrs.order_confirm : 0
  this.description = attrs.description ? attrs.description : []
  this.avg_bill = attrs.avg_bill ? attrs.avg_bill : ''
  this.phone = attrs.phone ? attrs.phone : ''
  this.telegram = attrs.telegram ? attrs.telegram : ''
  this.instagram = attrs.instagram ? attrs.instagram : ''
  this.split = attrs.split ? attrs.split : 'with_payment'
  this.change_amount = attrs.change_amount ? attrs.change_amount : false
  this.server_login = attrs.server_login ? attrs.server_login : ''
  this.server_pass = attrs.server_pass ? attrs.server_pass : ''
  this.server_url = attrs.server_url ? attrs.server_url : ''
  this.scan_enabled = attrs.scan_enabled ? attrs.scan_enabled : false
  this.with_payment = attrs.with_payment ? attrs.with_payment : false
  if (attrs.default_option === null || attrs.default_option === undefined) {
    this.default_option = 1
  } else {
    this.default_option = attrs.default_option
  }
}

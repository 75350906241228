<template>
  <a-modal
    title="Выбор адреса"
    :visible="modal"
    :closable="true"
    @cancel="close"
    @ok="save"
    centered
  >
    <div id="yandex-map"></div>
  </a-modal>
</template>

<script setup>
import { watch } from "vue";
import axios from "axios";

const props = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  coords: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['hide-map'])

let coordinates = [];
let address = '';
let address_description = '';

const close = () => {
  emit('hide-map', false);
  coordinates = [];
  address = '';
  address_description = '';
}
const save = () => {
  emit('hide-map', {
    'coordinates': coordinates,
    'address': address,
    'address_description': address_description,
  });
  coordinates = [];
  address = '';
  address_description = '';
}

const initializeYandexMap = () => {
  let mapDiv = document.getElementById('yandex-map');
  if (mapDiv && mapDiv.hasChildNodes())
    return;

  window.ymaps.ready(() => {
    let map = new window.ymaps.Map("yandex-map", {
      center: [41.31115, 69.279737],
      zoom: 11,
      controls: ['fullscreenControl'],
      searchControlProvider: 'yandex#search',
    });

    if (props.coords) {
      let currentAddress = props.coords.split(',');
      let currentPlacemark = new window.ymaps.Placemark(currentAddress);
      map.geoObjects.add(currentPlacemark);
      coordinates = currentAddress;
    }

    map.events.add('click', (e) => {
      map.geoObjects.each((context) => {
        map.geoObjects.remove(context);
      });
      let placemark = new window.ymaps.Placemark(e.get('coords'));
      map.geoObjects.add(placemark);
      coordinates = e.get('coords');
      getAddress(coordinates)
      // window.ymaps.geocode(coordinates).then((res) => {
      //   const firstGeoObject = res.geoObjects.get(0);
      //   address = firstGeoObject
      //     .getAddressLine()
      //     .replace(firstGeoObject.getCountry() + ", ", "");
      // })
    });
  });
}

const getAddress = (coordinates) => {
  const url = 'https://geocode-maps.yandex.ru/1.x/?apikey=e9fe7cab-eb17-4480-976c-a9bd1756a5c1&format=json&geocode=' + coordinates[1] + ',' + coordinates[0]
  axios.get(url)
    .then((res) => {
      const addressResponse = res.data.response.GeoObjectCollection.featureMember[0]
      address = addressResponse['GeoObject']['name']
      address_description = addressResponse['GeoObject']['description']
    })
}

watch(() => {
  if (props.modal) {
    initializeYandexMap()
  }
})

</script>

<style>
#yandex-map {
  width: 100%;
  height: 400px;
  overflow: hidden;
}
</style>

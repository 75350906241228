<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить филиал</a-button>
  <a-modal
    class="store-create-modal"
    title="Добавить филиал"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form :model="store" v-show="!loading && !confirmLoading">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <a-upload
              name="logo_url"
              list-type="picture-card"
              class="logo-uploader"
              :show-upload-list="false"
              :headers="getHeaders()"
              :action="getUrl"
              :before-upload="beforeUpload"
              @change="uploadImage"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <PlusOutlined v-else></PlusOutlined>
                <div class="ant-upload-text">Логотип</div>
              </div>
            </a-upload>
            <a-form-item label="НДС по умолчанию">
              <a-input
                placeholder="НДС"
                v-model:value="store.default_product_tax"
              />
            </a-form-item>
            <a-form-item label="Оплата за счет">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="store.pay_for_bill"/>
            </a-form-item>
            <div class="code-box-meta markdown" v-if="store.pay_for_bill">
              <div class="code-box-title">
                <span>Реквизиты оплаты</span>
              </div>
              <a-form-item label="ИНН (ПИНФЛ для ИП)">
                <a-input
                  class="w-100"
                  v-model:value="store.tin"
                />
              </a-form-item>
              <a-form-item label="МФО">
                <a-input
                  v-model:value="store.mfo"
                  v-maska="'#####'"
                />
              </a-form-item>
              <a-form-item label="Расч. счет">
                <a-input
                  v-model:value="store.account_no"
                  v-maska="'####################'"
                />
              </a-form-item>
            </div>
          </div>
          <div class="col-md-6">
            <a-form-item label="Название филиала">
              <a-input
                ref="branchName"
                v-model:value="store.name"
                placeholder="Введите название филиала"
              />
            </a-form-item>
            <a-form-item label="Бренд">
              <a-select
                ref="brand_id"
                v-model:value="store.brand_id"
                :options="brands"
                :option-filter-prop="'label'"
                show-search
              >
              </a-select>
            </a-form-item>
            <a-form-item label="Тип кассовой системы">
              <a-select
                ref="pos_type_id"
                v-model:value="store.pos_type_id"
                :options="posTypes"
              >
              </a-select>
            </a-form-item>
            <a-form-item label="SPOT ID" v-if="isPoster">
              <a-input
                ref="spot_id"
                v-model:value="store.spot_id"
              />
            </a-form-item>
            <a-form-item label="Логин сервера" v-if="isPoster || isJowi || isIiko">
              <a-input
                  placeholder="Введите логин"
                  v-model:value="store.server_login"
              />
            </a-form-item>
            <a-typography-link
                v-if="isPoster && store.server_login"
                :href="'https://' + store.server_login + '.joinposter.com/api/auth?application_id=2879&redirect_uri=https://mesh.multicard.uz/tips/callback/poster&response_type=code'"
                :style="{ display: 'block', marginTop: '6px' }"
                target="_blank"
                code
            >Cсылка для авторизации</a-typography-link>
            <a-form-item label="Пароль сервера" v-if="isPoster || isJowi || isIiko">
              <a-input
                  placeholder="Введите пароль"
                  v-model:value="store.server_pass"
              />
            </a-form-item>
            <a-form-item label="URL сервера" v-if="isPoster || isJowi || isIiko">
              <a-input
                  placeholder="Введите URL"
                  v-model:value="store.server_url"
              />
            </a-form-item>
            <a-form-item label="Включить чаевые">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="store.tips_enabled"
              />
            </a-form-item>
            <div class="code-box-meta markdown" v-if="store.tips_enabled">
              <div class="code-box-title">
                <span>Чаевые</span>
              </div>
              <a-form-item label="Варианты оплаты">
                <a-select
                  v-model:value="store.payment_options"
                  mode="tags"
                  :max-tag-count="4"
                  placeholder="Варианты оплаты"
                ></a-select>
              </a-form-item>
              <a-form-item label="Варианты чаевых">
                <a-select
                  v-model:value="store.tips_options"
                  mode="tags"
                  :max-tag-count="4"
                  placeholder="Варианты чаевых"
                ></a-select>
              </a-form-item>
              <a-form-item class="default-option-item">
                <template #label>
                  <div class="col-md-12">
                    <div class="row justify-content-between">
                      <div class="float-left">Вариант по умолчанию:</div>
                      <div class="float-right">
                        <a-switch
                          class="float-right"
                          checked-children="Да"
                          un-checked-children="Нет"
                          v-model:checked="store.change_amount"/>
                        <div class="float-right">Ручной ввод:&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </template>
                <a-radio-group v-model:value="store.default_option">
                  <a-radio-button
                    v-for="key in store.payment_options.length + 1"
                    :value="key"
                    :key="key"
                  >{{ key === 1 ? 'Без чаевых' : store.payment_options[key - 2] }}</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="Настройки доставки">
                <a-select
                  ref="split"
                  v-model:value="store.split"
                >
                  <a-select-option value="with_payment">Оплата с доставкой</a-select-option>
                  <a-select-option value="split_delivery">Доставка отдельно</a-select-option>
                  <a-select-option value="same_page">На одной странице</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Тип кошелька">
                <a-select
                  ref="wallet_type"
                  v-model:value="store.wallet_type"
                >
                  <!--                <a-select-option value="p2p">P2P</a-select-option>-->
                  <a-select-option value="personal">Личный</a-select-option>
                  <a-select-option value="shared">Общий</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Комиссия за чаевые">
                <a-input
                  placeholder="Введите комиссию"
                  suffix="%"
                  type="number"
                  v-model:value="store.tips_commission"
                />
              </a-form-item>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <a-tooltip>
                        <template #title>
                          <div class="text-center">Запрашивать перед оплатой</div>
                        </template>
                        <a-form-item label="Модалка:">
                          <a-switch
                            checked-children="Вкл"
                            un-checked-children="Выкл"
                            v-model:checked="store.ask_mode"
                          >
                          </a-switch>
                        </a-form-item>
                      </a-tooltip>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-tooltip>
                        <template #title>
                          <div class="text-center">Коммиссию за чаевые платит гость</div>
                        </template>
                        <a-form-item label="Коммиссия:">
                          <a-switch
                            checked-children="Вкл"
                            un-checked-children="Выкл"
                            v-model:checked="store.pay_guest"
                          >
                          </a-switch>
                        </a-form-item>
                      </a-tooltip>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-tooltip>
                        <template #title>
                          <div class="text-center">Отображать звездочки и комментарии на странице чаевых</div>
                        </template>
                        <a-form-item label="Рейтинг">
                          <a-switch
                            checked-children="Вкл"
                            un-checked-children="Выкл"
                            v-model:checked="store.show_rating"/>
                        </a-form-item>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="code-box-meta markdown mt-5">
              <div class="code-box-title">
                <span>Дополнительные параметры</span>
              </div>
            </div>
            <a-form-item style="position: relative" class="tips-notification-item">
              <template #label>
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="float-left">Отзывы:</div>
                    <div class="float-right">
                      <a-switch
                        class="float-right"
                        checked-children="Да"
                        un-checked-children="Нет"
                        v-model:checked="store.tips_notification"/>
                      <div class="float-right">Скрыть сумму:&nbsp;</div>
                    </div>
                  </div>
                </div>
              </template>
              <a-input
                @change="fetchChannelName($event, 'tips')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_tips"
              />
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="tipsChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px' }">{{ tipsChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Счета">
              <a-input
                @change="fetchChannelName($event, 'bill')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_bill"
              />
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="billChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ billChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Бронирование">
              <a-input
                @change="fetchChannelName($event, 'reserve')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_reserve"
              />
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="reserveChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ reserveChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Заказы">
              <a-input
                @change="fetchChannelName($event, 'order')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_order"
              />
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="orderChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ orderChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Доставка">
              <a-input
                @change="fetchChannelName($event, 'delivery')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_delivery"
              />
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="deliveryChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ deliveryChannelName }}</a-typography-text>
            </a-form-item>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Вкл. фискализацию</div>
                      </template>
                      <a-form-item label="Фискализация:">
                        <a-switch
                          checked-children="Вкл"
                          un-checked-children="Выкл"
                          v-model:checked="store.tax"
                        >
                        </a-switch>
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Скрыть лого на гл. странице</div>
                      </template>
                      <a-form-item label="Скрыть лого">
                        <a-switch
                          checked-children="Скр."
                          un-checked-children="Пок."
                          v-model:checked="store.hide_logo"/>
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Фильтровать по филиалу в отчетах</div>
                      </template>
                      <a-form-item label="Фильтр по кешдеску:">
                        <a-switch
                          checked-children="Вкл"
                          un-checked-children="Выкл"
                          v-model:checked="store.has_cashdesk"/>
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';
import {ref, reactive, toRaw, watch, nextTick, onMounted} from 'vue';
import {getBase64} from "@/helpers/base64"
import {getHeaders} from "@/helpers/header"
import apiClient from "@/services/axios";
import {message} from 'ant-design-vue';
import Store from "@/models/Store";
import {debounce} from "lodash";

const props = defineProps({
  posTypes: {
    type: Array,
    default: ref([]),
  },
  brands: {
    type: Array,
    default: ref([]),
  },
  classifications: {
    type: Array,
    default: ref([]),
  },
  cuisines: {
    type: Array,
    default: ref([]),
  },
  additionals: {
    type: Array,
    default: ref([]),
  },
  stickers: {
    type: Array,
    default: ref([]),
  },
})

const emit = defineEmits(['reload']);

let imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  isJowi = ref(false),
  isPoster = ref(false),
  isIiko = ref(false),
  branchName = ref("branchName"),
  tipsChannelFetching = ref(false),
  tipsChannelName = ref(''),
  billChannelFetching = ref(false),
  billChannelName = ref(''),
  reserveChannelFetching = ref(false),
  reserveChannelName = ref(''),
  orderChannelFetching = ref(false),
  orderChannelName = ref(''),
  deliveryChannelFetching = ref(false),
  deliveryChannelName = ref(''),
  store = reactive(new Store({}));

const
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.logo_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  openModal = () => {
    modalVisible.value = true;
    imageUrl.value = "";
    nextTick(() => branchName.value?.focus())
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/store/upload'
  },
  resetForm = () => {
    Object.assign(store, new Store({}));
    modalVisible.value = false;
  },
  fetchChannelName = debounce((event, type) => {
    switch (type) {
      case 'tips':
        tipsChannelFetching.value = true;
        break;
      case 'bill':
        billChannelFetching.value = true;
        break;
      case 'reserve':
        reserveChannelFetching.value = true;
        break;
      case 'order':
        orderChannelFetching.value = true;
        break;
      case 'delivery':
        deliveryChannelFetching.value = true;
        break;
    }

    fetch(`https://api.telegram.org/bot1886153513:AAFI8UfPx4bnD1FSwWWwolQYhlnrRvRm74Y/getChat?chat_id=${event.target.value}`)
      .then(response => response.json())
      .then((res) => {
        switch (type) {
          case 'tips':
            tipsChannelFetching.value = false
            tipsChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'bill':
            billChannelFetching.value = false
            billChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'reserve':
            reserveChannelFetching.value = false
            reserveChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'order':
            orderChannelFetching.value = false
            orderChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'delivery':
            deliveryChannelFetching.value = false
            deliveryChannelName.value = (res.ok) ? res.result.title : ''
            break;
        }
      })
  }, 300),
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(store);
    model.payment_options = JSON.stringify(model.payment_options);
    model.tips_options = JSON.stringify(model.tips_options);
    model.default_option = model.default_option - 1;
    model.ask_mode = model.ask_mode ? 1 : 0
    model.tax = model.tax ? 1 : 0
    model.tips_enabled = model.tips_enabled ? 1 : 0
    model.tips_notification = model.tips_notification ? 1 : 0;

    return apiClient.post('/store', model).then(function (response) {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      model.payment_options = JSON.parse(model.payment_options)
      model.tips_options = JSON.parse(model.tips_options)
      model.default_option = model.default_option + 1;
      confirmLoading.value = false;
      emit('reload')
    })
  }

watch(() => {
  if (modalVisible.value) {
    tipsChannelName.value = ''
    billChannelName.value = ''
    reserveChannelName.value = ''
    orderChannelName.value = ''
  }
})
watch(() => {
  const posType = props.posTypes[store.pos_type_id];
  if (posType) {
    isJowi.value = posType.label === 'JOWI'
    isPoster.value = posType.label === 'Poster'
    isIiko.value = posType.label === 'IIKO'
  } else {
    isJowi.value = false
    isPoster.value = false
    isIiko.value = false
  }
});

onMounted(() => {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      if (modalVisible.value)
        create()
    }
  });
})

</script>

<style scoped>
@import "./create.css";
</style>
